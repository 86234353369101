import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelResearchProjectsSettings: {
    id: 'Research projects from eCampus',
    defaultMessage: 'Research projects from eCampus',
  },
  labelPersonsOrOrgUnits: {
    id: 'Persons or organizational units',
    defaultMessage: 'Persons or organizational unit',
  },
  labelSelectPersonOrOrgUnit: {
    id: 'Select person or organizational unit',
    defaultMessage: 'Select person or organizational unit',
  },
  labelPersonOrOrgUnit: {
    id: 'Person or organizational unit',
    defaultMessage: 'Person or organizational unit',
  },
  labelAddPersonOrOrgUnit: {
    id: 'Add person or organizational unit',
    defaultMessage: 'Add person or organizational unit',
  },
  labelFilterByStatus: {
    id: 'Filter by status',
    defaultMessage: 'Filter by status',
  },
  labelOngoing: {
    id: 'Ongoing',
    defaultMessage: 'Ongoing',
  },
  labelCompleted: {
    id: 'Completed',
    defaultMessage: 'Completed',
  },
  labelSortBy: {
    id: 'Sort by',
    defaultMessage: 'Sort by',
  },
  labelSortReverse: {
    id: 'Reverse sort',
    defaultMessage: 'Reverse sort',
  },
  labelTitle: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  labelStart: {
    id: 'Start date',
    defaultMessage: 'Start date',
  },
  labelEnd: {
    id: 'End date',
    defaultMessage: 'End date',
  },
  labelColumnsCount: {
    id: 'Count of columns',
    defaultMessage: 'Count of columns',
  },
  labelDefault: {
    id: 'Default',
    defineMessages: 'Default',
  },
});

const getSortByChoices = (intl) => {
  return [
    ['title', intl.formatMessage(messages.labelTitle)],
    ['start', intl.formatMessage(messages.labelStart)],
    ['end', intl.formatMessage(messages.labelEnd)],
  ];
};

const PersonOrOrgUnitSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelPersonOrOrgUnit),
    addMessage: intl.formatMessage(messages.labelAddPersonOrOrgUnit),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['href'],
      },
    ],

    properties: {
      href: {
        title: intl.formatMessage(messages.labelSelectPersonOrOrgUnit),
        widget: 'object_browser',
        widgetOptions: {
          pattern_options: { selectableTypes: ['Person', 'OrgUnit'] },
        },
        mode: 'link',
        selectedItemAttrs: ['ecampus_id', 'portal_type'],
      },
    },
  };
};

const ResearchProjectsSchema = (intl) => {
  const MyPersonOrOrgUnitSchema = PersonOrOrgUnitSchema(intl);
  const sortByChoices = getSortByChoices(intl);
  return {
    title: intl.formatMessage(messages.labelResearchProjectsSettings),

    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'items',
          'filter_by_status',
          'sort_by',
          'sort_reverse',
          'grid_columns',
        ],
      },
    ],

    properties: {
      items: {
        title: intl.formatMessage(messages.labelPersonsOrOrgUnits),
        schema: MyPersonOrOrgUnitSchema,
        widget: 'object_list',
      },
      filter_by_status: {
        title: intl.formatMessage(messages.labelFilterByStatus),
        type: 'string',
        factory: 'Choice',
        choices: [
          ['ongoing', intl.formatMessage(messages.labelOngoing)],
          ['completed', intl.formatMessage(messages.labelCompleted)],
        ],
        isMulti: false,
      },
      sort_by: {
        title: intl.formatMessage(messages.labelSortBy),
        type: 'string',
        factory: 'Choice',
        choices: sortByChoices,
        isMulti: false,
        default: 'title',
      },
      sort_reverse: {
        title: intl.formatMessage(messages.labelSortReverse),
        type: 'boolean',
        default: false,
      },
      grid_columns: {
        title: intl.formatMessage(messages.labelColumnsCount),
        type: 'integer',
        default: 2,
      },
    },

    required: ['href'],
  };
};

export default ResearchProjectsSchema;
