import { RenderBlocks } from '@plone/volto/components';
import { Tab } from 'semantic-ui-react';

const View = (props) => {
  const { data, metadata } = props;
  const tabsData = data.data || { block: {}, blocks_layout: { items: [] } };
  const tabsList = tabsData.blocks_layout?.items || [];
  const tabs = tabsData.blocks || {};

  const panes = tabsList.map((id) => {
    return {
      id: id,
      menuItem: tabs[id].title || `Tab ${tabsList.indexOf(id) + 1}`,
      pane: (
        <Tab.Pane key={id}>
          <div
            id={tabs[id]?.title || `Tab ${tabsList.indexOf(id) + 1}`}
            className="tab-name"
          >
            <div tabIndex={0} role="tabpanel" id={'tab-pane-' + id}>
              <RenderBlocks {...props} metadata={metadata} content={tabs[id]} />
            </div>
          </div>
        </Tab.Pane>
      ),
    };
  });

  return (
    <Tab
      className="tabs-block"
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      renderActiveOnly={false}
    />
  );
};

export default View;
