/*
  Component shadowed to display proper error message to user,
  if translation creation fails (for example because of missing
  user permissions).

  see:
  https://gitlab.uni-koblenz.de/cms-developer/volto-uniko/-/issues/728
*/
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Message } from 'semantic-ui-react';
import {
  changeLanguage,
  getTranslationLocator,
  getContent,
} from '@plone/volto/actions';
import { flattenToAppURL, toGettextLang } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

const CreateTranslation = (props) => {
  const dispatch = useDispatch();
  const { language, translationOf } = props.location.state;
  const [translationLocation, setTranslationLocation] = React.useState(null);
  const [translationObject, setTranslationObject] = React.useState(null);
  const [error, setError] = React.useState(false);
  const languageFrom = useSelector((state) => state.intl.locale);

  React.useEffect(() => {
    // Only on mount, we dispatch the locator query
    dispatch(getTranslationLocator(translationOf, language))
      .then((resp) => {
        setTranslationLocation(resp['@id']);
      })
      // CUSTOMIZATION: catch errors to display proper error message to user
      .catch((err) => {
        setError(err);
      });

    //and we load the translationObject
    dispatch(getContent(translationOf, null, 'translationObject'))
      .then((resp) => {
        setTranslationObject(resp);
      })
      // CUSTOMIZATION: catch errors to display proper error message to user
      .catch((err) => {
        setError(err);
      });

    // On unmount we dispatch the language change
    return () => {
      // We change the interface language
      if (config.settings.supportedLanguages.includes(language)) {
        const langFileName = toGettextLang(language);
        import('@root/../locales/' + langFileName + '.json').then((locale) => {
          dispatch(changeLanguage(language, locale.default));
        });
      }
    };
    // On mount only
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  // CUSTOMIZATION: catch errors to display proper error message to user
  if (error) {
    const curPath = props.location.state.translationOf;
    const body = `Lieber CMS-Support,%0D%0A%0D%0Abitte legen Sie die Übersetzung von ${curPath} an.%0D%0A%0D%0ADanke und viele Grüße,%0D%0A`;
    return (
      <Container>
        <Message error>
          <FormattedMessage
            id="You can not create a translation of this object (Error {code})."
            defaultMessage="You can not create a translation of this object (Error {code})."
            values={{
              code: error.status,
            }}
          />
          <br />
          <br />
          <FormattedMessage
            id="If you still want this translation to be created, please <mail>contact the support</mail>."
            defaultMessage="If you still want this translation to be created, please <mail>contact the support</mail>."
            values={{
              path: '/en',
              mail: (chunks) => (
                <b>
                  <a
                    href={`mailto:cms@uni-koblenz.de?subject=Anfrage: Übersetzung anlegen&body=${body}`}
                  >
                    {chunks}
                  </a>
                </b>
              ),
            }}
          />
        </Message>
        <Button onClick={() => props.history.goBack()}>
          <FormattedMessage id="Back" defaultMessage="Back" />
        </Button>
      </Container>
    );
  }

  return (
    translationLocation &&
    translationObject && (
      <Redirect
        to={{
          pathname: `${flattenToAppURL(translationLocation)}/add`,
          search: `?type=${props.location.state.type}`,
          state: {
            translationOf: props.location.state.translationOf,
            language: props.location.state.language,
            translationObject: translationObject,
            languageFrom,
          },
        }}
      />
    )
  );
};

export default CreateTranslation;
