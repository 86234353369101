import { defineMessages } from 'react-intl';

const messages = defineMessages({
  labelEventMetadata: {
    id: 'Event metadata',
    defaultMessage: 'Event metadata',
  },
  labelDefault: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  labelCustomField: {
    id: 'Custom field',
    defaultMessage: 'Custom field',
  },
  labelAddCustomField: {
    id: 'Add field',
    defaultMessage: 'Add field',
  },
  labelCustomFields: {
    id: 'Custom fields',
    defaultMessage: 'Custom fields',
  },
  labelHeading: {
    id: 'Heading',
    defaultMessage: 'Heading',
  },
  labelValue: {
    id: 'Content',
    defaultMessage: 'Content',
  },
});

const CustomFieldSchema = (intl) => {
  return {
    title: intl.formatMessage(messages.labelCustomField),
    addMessage: intl.formatMessage(messages.labelAddCustomField),

    fieldsets: [
      {
        id: 'default',
        title: intl.formatMessage(messages.labelDefault),
        fields: ['heading', 'value'],
      },
    ],

    properties: {
      heading: {
        title: intl.formatMessage(messages.labelHeading),
      },
      value: {
        title: intl.formatMessage(messages.labelValue),
        widget: 'textarea',
      },
    },

    required: ['title', 'name'],
  };
};

export const MetadataEventSchema = (intl) => {
  const MyCustomFieldSchema = CustomFieldSchema(intl);
  return {
    title: intl.formatMessage(messages.labelEventMetadata),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['custom_fields'],
      },
    ],

    properties: {
      custom_fields: {
        title: intl.formatMessage(messages.labelCustomFields),
        schema: MyCustomFieldSchema,
        widget: 'object_list',
      },
    },

    required: [],
  };
};

export default MetadataEventSchema;
