import { defineMessages, useIntl } from 'react-intl';
import { Card } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';

const messages = defineMessages({
  projectDuration: {
    id: 'Project duration',
    defaultMessage: 'Project duration',
  },
  projectStatus: {
    id: 'Status',
    defaultMessage: 'Status',
  },
});

const ResearchProjectCard = ({
  project,
  linksDisabled = false,
  hideState = false,
}) => {
  const intl = useIntl();

  const fStart = project.start || false;
  const fEnd = project.end || false;
  const durationStr = fStart && fEnd ? `${fStart} - ${fEnd}` : fStart;

  return (
    <Card className="project-card">
      <Card.Content>
        <ConditionalLink
          condition={!linksDisabled && project.url?.length > 0}
          to={project.url}
          target="_blank"
        >
          {project.title}
        </ConditionalLink>
        {project.acronym && <Card.Meta>{project.acronym}</Card.Meta>}
      </Card.Content>
      {project.status && !hideState && (
        <Card.Content
          extra
          title={intl.formatMessage(messages.projectDuration)}
        >
          {project.status}
        </Card.Content>
      )}
      {durationStr && (
        <Card.Content
          extra
          title={intl.formatMessage(messages.projectDuration)}
        >
          {durationStr}
        </Card.Content>
      )}
    </Card>
  );
};

export default ResearchProjectCard;
