import Slugger from 'github-slugger';
import {
  getBlocksFieldname,
  getBlocksLayoutFieldname,
  normalizeString,
} from '@plone/volto/helpers';
import config from '@plone/volto/registry';

/* Returns all ToC entries.
   Works with nested blocks (columns, accordions).

   Each entry has this structure:
   {
      id: ID of referenced block (persists over block lifetime)
      key: Anchor slug for this block (can change over block lifetime)
      override_toc: Block property allowing users to create custom ToC entry
      plaintext: Plain text
      text: Text of ToC entry (usually title)
      type: ToC level
   }
*/
export function getToCEntries(blocks, blocks_layout) {
  let headings = [];
  if (!blocks || !blocks_layout) return [];
  blocks_layout.items
    .filter((blockId) => blocks[blockId])
    .forEach((blockId) => {
      const block = blocks[blockId];
      if (block.data?.blocks && block.data?.blocks_layout) {
        block.data.blocks_layout.items.forEach((innerBlockId) => {
          headings = headings.concat(
            getToCEntries(
              block.data.blocks[innerBlockId].blocks,
              block.data.blocks[innerBlockId].blocks_layout,
            ),
          );
        });
      }
      if (config.blocks.blocksConfig[block['@type']]?.tocEntry) {
        const entry = config.blocks.blocksConfig[block['@type']]?.tocEntry(
          block,
          { levels: [2, 3, 4] },
        );
        /* only allow level h2 to h4 (see slate schemaEnhancer in config.js) */
        const level2class = {
          2: 'header-two',
          3: 'header-three',
          4: 'header-four',
        };
        if (entry?.length > 1 && entry[1]?.length > 0) {
          const level = entry[0];
          const title = entry[1];
          const text = title || block.plaintext;
          const slug = block.override_toc
            ? Slugger.slug(normalizeString(block.plaintext))
            : Slugger.slug(normalizeString(text)) || blockId;
          headings.push({
            id: blockId,
            key: slug,
            override_toc: block.override_toc,
            plaintext: block.plaintext || '',
            text: text,
            type: level2class[level] || 'header-two',
          });
        }
      }
    });
  return headings;
}

/*
  Looks for block with `blockId`.
  Works with nested blocks (columns, accordions).
  Returns `false` if not found.
*/
export function getBlock(blockId, blocks, blocks_layout) {
  if (!blocks || !blocks_layout) return false;
  const blockIds = Object.keys(blocks);
  if (blockIds.indexOf(blockId) > -1) return blocks[blockId];
  for (let i = 0; i < blockIds.length; i++) {
    const curBlockId = blockIds[i];
    const block = blocks[curBlockId];
    if (block.data?.blocks && block.data?.blocks_layout) {
      for (let j = 0; j < block.data.blocks_layout.items.length; j++) {
        const innerBlockId = block.data.blocks_layout.items[j];
        const innerBlock = getBlock(
          blockId,
          block.data.blocks[innerBlockId].blocks,
          block.data.blocks[innerBlockId].blocks_layout,
        );
        if (innerBlock) return innerBlock;
      }
    }
  }
  return false;
}

export function getAnchorSlugOfBlock(blockId, content) {
  if (!content) return blockId;

  const blocksFieldname = getBlocksFieldname(content);
  const blocksLayoutFieldname = getBlocksLayoutFieldname(content);
  const block = getBlock(
    blockId,
    content[blocksFieldname],
    content[blocksLayoutFieldname],
  );

  if (block === false) return blockId;
  const entry = config.blocks.blocksConfig[block['@type']]?.tocEntry(block, {
    levels: [2, 3, 4],
  });
  const text = entry
    ? entry.length > 1
      ? entry[1]
      : block.plaintext
    : block.plaintext;
  return block.override_toc
    ? Slugger.slug(normalizeString(block.plaintext))
    : Slugger.slug(normalizeString(text)) || blockId;
}
